import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import moment from "moment";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// main function

const ItemList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [selected, setSelected] = useState(null);
  const localWarehouse = localStorage.getItem("warehouse");
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    localWarehouse ? localWarehouse : "all"
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = async () => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/item?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=sequence&sortOrder=asc&search=` +
        search +
        `&warehouse_id=${selectedWarehouse}`
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, count, search, selectedWarehouse]);

  const getAllWarehouse = () => {
    AxiosBase.get(`/admin/warehouse?pageNo=0&rowsPerPage=1000000`).then(
      (response) => {
        setWarehouse(response.data.data);
      }
    );
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const getAllMenu = async () => {
    try {
      const res = await AxiosBase.get("/admin/menu?sortOrder=asc");
      setMenu(res?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getAllMenu();
  }, []);

  const deleteRecord = (row) => {
    setSelected(row);
    setShowConfirm(true);
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleDelete = () => {
    setShowConfirm(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/item/${selected?._id}`)
      .then((res) => {
        toast("Record deleted successfully", {
          type: "success",
          autoClose: 2000,
        });
        getData();
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Item</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Select
                  value={selectedWarehouse}
                  style={{ width: "40%" }}
                  onChange={(e) => {
                    localStorage.setItem("warehouse", e.target.value)
                    setSelectedWarehouse(e.target.value);
                  }}
                >
                  <MenuItem key="all" value="all">
                    All Warehouse
                  </MenuItem>
                  {warehouse.map((w) => {
                    return (
                      <MenuItem key={w._id} value={w._id}>
                        {w.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              <div className="merchant_list_filter_div">
                <img src="/Filter.svg" alt="" />
              </div>
              {props?.menu_permissions?.ADD && (
                <Link className="margin-auto" to={`/item/form/-1`}>
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
            <h3 className="merchant_list_inner_title">Item List</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Item ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Sequence</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow
                        key={row?._id}
                        style={{ background: "none", height: "60px" }}
                      >
                        <TableCell>
                          {props?.menu_permissions?.EDIT ? (
                            <Link
                              to={`/item/form/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "#1058ff",
                              }}
                            >
                              {row._id}
                            </Link>
                          ) : (
                            row?._id
                          )}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{(row?.amount || 0).toFixed(2)}</TableCell>
                        <TableCell>{row.gender}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD-MM-YYYY hh:mm A")}
                        </TableCell>
                        <TableCell>
                          {row?.status ? "Active" : "InActive"}
                        </TableCell>
                        <TableCell>{row?.sequence || 0}</TableCell>
                        <TableCell>
                          {props?.menu_permissions?.EDIT && (
                            <Link to={`/item/form/${row._id}`}>
                              <IconButton
                                className="merchant_tbl_edit_icon"
                                aria-label="more"
                                id="long-button"
                                aria-controls="long-menu"
                                aria-expanded={open1 ? "true" : undefined}
                                aria-haspopup="true"
                              >
                                <img src="/images/icon/editt.svg" alt="" />
                              </IconButton>
                            </Link>
                          )}
                          {props?.menu_permissions?.DELETE && (
                            <Button
                              className="list-delete-btn"
                              onClick={(e) => {
                                deleteRecord(row);
                              }}
                              variant="contained"
                              title="Detail"
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>

      <BootstrapDialog
        className="order_detail_refund_popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showConfirm}
      >
        <DialogContent>
          <Typography className="order_detail_refund_popup_title" gutterBottom>
            {
              <>
                {" "}
                <p>Confirm Delete</p>
                <span>Are you sure want to delete this Record?</span>
                <div className="order_detail_refund_popup_btn">
                  <Button
                    className="refund_save_btn"
                    variant="contained"
                    autoFocus
                    fullWidth
                    onClick={handleDelete}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="refund_cancel_btn"
                    variant="contained"
                    autoFocus
                    onClick={handleClose}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </div>
              </>
            }
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ItemList;
