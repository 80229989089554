import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.scss";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import List from "@material-ui/core/List";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import "./CollapseNavbar.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import Config from "../../config.json";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1456F5",
    borderRadius: "0 15px 15px 0",
    border: "none",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));
function CollapseNavBar(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const [openNavbar, setOpenNavbar] = React.useState(false);
  const [menuPermissions, setMenuPermissions] = React.useState({});

  const [opennavlink, setOpenNavlink] = React.useState(false);
  const [opennavlink1, setOpenNavlink1] = React.useState(false);
  const [opennavlink2, setOpenNavlink2] = React.useState(false);

  const [navbarData, setNavbarData] = React.useState({});
  const handleClickNavlink1 = () => {
    setOpenNavlink1(!opennavlink1);
  };
  const handleClickNavlink = () => {
    setOpenNavlink(!opennavlink);
  };

  const handleClickNavlink2 = () => {
    setOpenNavlink2(!opennavlink2);
  };

  const navs = [
    {
      icon: "/images/menu/dashboard.png",
      title: "Dashboard",
      url: "/dashboard",
      id: Config.PermissionId.Dashboard,
    },
    {
      icon: "/images/menu/customer.png",
      title: "Customers",
      url: "/customer",
      id: Config.PermissionId.Customer,
    },
    {
      icon: "/images/menu/order.png",
      title: "Order",
      url: "/order",
      id: Config.PermissionId.Order,
    },
    {
      icon: "/images/menu/acquirer.png",
      title: "Acquirer",
      url: "/acquirer",
      id: Config.PermissionId.Acquirer,
    },
    {
      icon: "/images/menu/payroll.png",
      title: "Employee Payroll",
      url: "/payroll",
      id: Config.PermissionId.Acquirer,
    },
    {
      icon: "/images/menu/order.png",
      title: "Switch Order",
      url: "/switch",
      id: Config.PermissionId.Order,
    },
    {
      icon: "/images/menu/transaction.png",
      title: "Transaction",
      url: "/transaction",
      id: Config.PermissionId.Transaction,
    },
    {
      icon: "/images/menu/vehicle.png",
      title: "Vehicle",
      url: "/vehicle",
      id: Config.PermissionId.Vehicle,
    },
    {
      icon: "/images/menu/master.png",
      title: "Master Config",
      url: "#",
      id: Config.PermissionId.MasterConfig,
      submenu: [
        {
          title: "Banner",
          link: "/config?id=0",
          icon: "/images/menu/banner.png",
          id: Config.PermissionId.Banner,
        },
        {
          title: "Our Services",
          link: "/config?id=1",
          icon: "/images/menu/service.png",
          id: Config.PermissionId.OurServices,
        },
        {
          title: "Time Slot",
          link: "/config?id=2",
          icon: "/images/menu/timeSlot.png",
          id: Config.PermissionId.TimeSlot,
        },
        {
          title: "Offer",
          link: "/config?id=3",
          icon: "/images/menu/offer.png",
          id: Config.PermissionId.Offer,
        },
        {
          title: "Coupon",
          link: "/config?id=4",
          icon: "/images/menu/offer.png",
          id: Config.PermissionId.Offer,
        },
        {
          title: "Customer App",
          link: "/config?id=5",
          icon: "/images/menu/app_config.png",
          id: Config.PermissionId.CustomerApp,
        },
        {
          title: "Rider App",
          link: "/config?id=6",
          icon: "/images/menu/app_config.png",
          id: Config.PermissionId.RiderApp,
        },
        {
          title: "Help FAQs",
          link: "/config?id=7",
          icon: "/images/menu/app_config.png",
          id: Config.PermissionId.RiderApp,
        },
      ],
      onclick: handleClickNavlink,
      open: opennavlink,
    },
    {
      icon: "/images/menu/warehouse.png",
      title: "Warehouse",
      url: "/warehouse",
      id: Config.PermissionId.Warehouse,
    },
    {
      icon: "/images/menu/item.png",
      title: "Item",
      url: "/item",
      id: Config.PermissionId.Warehouse,
    },
    {
      icon: "/images/menu/tag.png",
      title: "Tag No",
      url: "/tag",
      id: Config.PermissionId.Tag,
    },
    {
      icon: "/images/menu/user.png",
      title: "Users",
      url: "/user",
      id: Config.PermissionId.User,
    },
    {
      icon: "/images/menu/access-control.png",
      title: "Access Control",
      url: "#",
      id: Config.PermissionId.AccessControl,
      submenu: [
        {
          title: "Menu",
          link: "/menu",
          icon: "/images/menu/menu.png",
          id: Config.PermissionId.Menu,
        },
        {
          title: "Role",
          link: "/role",
          icon: "/images/menu/role.png",
          id: Config.PermissionId.Role,
        },
      ],
      onclick: handleClickNavlink2,
      open: opennavlink2,
    },
    {
      icon: "/images/menu/support.png",
      title: "Support",
      url: "/support",
      id: Config.PermissionId.Support,
    },
    {
      icon: "/images/menu/subscriber.png",
      title: "Subscriber",
      url: "/subscribe",
      id: Config.PermissionId.Subscribe,
    },
    {
      icon: "/images/menu/crm.png",
      title: "CRM",
      url: "/crm",
      id: Config.PermissionId.Customer,
    },
    {
      icon: "/images/menu/accounting.png",
      title: "Account Management",
      url: "/account",
      id: Config.PermissionId.Customer,
    },
  ];

  useEffect(() => {
    setMenuPermissions(props?.user_data?.menu_permissions || {});
  }, []);

  React.useEffect(() => {
    setNavbarData(props.NavBarData);
    setChecked(true);
  }, []);

  React.useEffect(() => {
    setChecked(props.openNavbar);
  }, [props.openNavbar]);

  return (
    <Paper className={`navbar_paper ${styles.css_cets00_MuiPaper_root}`}>
      <div className="navbar_arrow_div">
        <IconButton className={!checked ? `position_change` : "btn_icon_rad"}>
          {!checked ? (
            <ArrowBackIosNewOutlinedIcon
              onClick={props.openNavbarHandler}
              className="colleps_arrow "
            />
          ) : (
            <ArrowForwardIosOutlinedIcon
              onClick={props.closeNavbarHandler}
              className="colleps_arrow"
            />
          )}
        </IconButton>
      </div>

      <div className="navbar_logo_dev">
        <div>
          <img
            src="/logo.svg"
            alt="Logo"
            width="260px"
            height="90px"
            style={{ marginTop: "-50px", marginBottom: "-20px" }}
          />
        </div>
      </div>

      <List className={`navlist ${styles.link_list_navbar}`}>
        {navs.map((nav, index) => {
          {
            if (nav.id == "Default" || menuPermissions[nav.id]?.VIEW) {
              return (
                <>
                  <NavLink
                    onMouseEnter={props.closeNavbarHandler}
                    onMouseLeave={props.openNavbarHandler}
                    className={styles.navlink}
                    activeClassName={!nav.submenu ? styles.navlink_active : ""}
                    to={nav.url}
                    key={index}
                    onClick={nav.submenu ? nav.onclick : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <img src={nav.icon} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        className="navbar_lits_item_text"
                        primary={nav.title}
                      />
                      {nav.submenu ? (
                        nav.open ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : (
                        ""
                      )}
                    </ListItem>
                  </NavLink>
                  {nav.submenu ? (
                    <div className="configuration_collaps">
                      {nav.submenu.map((s) => {
                        {
                          if (
                            s.id == "Default" ||
                            menuPermissions[s.id]?.VIEW
                          ) {
                            if (
                              s.custodian_page &&
                              !props.user_data.is_first_custodian &&
                              !props.user_data.is_second_custodian
                            ) {
                              return null;
                            }
                            return (
                              <>
                                <Collapse
                                  in={nav.open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    <NavLink
                                      onMouseEnter={props.closeNavbarHandler}
                                      onMouseLeave={props.openNavbarHandler}
                                      className={styles.navlink}
                                      to={s.link}
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon>
                                          <img src={s.icon} alt="" />
                                        </ListItemIcon>

                                        <ListItemText primary={s.title} />
                                      </ListItemButton>
                                    </NavLink>
                                  </List>
                                </Collapse>
                              </>
                            );
                          }
                        }
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            }
          }
        })}

        <ListItem
          onMouseEnter={props.closeNavbarHandler}
          onMouseLeave={props.openNavbarHandler}
          button
          onClick={() => props.onLogout()}
        >
          <ListItemIcon>
            <img src="/images/menu/logout.png" alt="" />
          </ListItemIcon>
          <ListItemText className="logout_text" primary={"Logout"} />
        </ListItem>
      </List>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    user_data: state.auth.user_data,
    NavBarData: state.auth.NavbarAccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseNavBar);
