import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import "./index.css";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import Filter from "../../../utils/dateFilter/Filter";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const AccountList = (props) => {
  const [revenue, setRevenue] = React.useState([]);
  const [spinner, setSpinner] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("all");
  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "INR",
    country: "IND",
  });

  const getAllWarehouse = () => {
    AxiosBase.get(`/admin/warehouse?pageNo=0&rowsPerPage=1000000`).then(
      (response) => {
        setWarehouse(response.data.data);
      }
    );
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const getData = (from_date, to_date) => {
    let query = "?warehouse_id=" + selectedWarehouse;
    if (from_date && to_date) {
      query += `&startDate=${from_date}&endDate=${to_date}`;
    }
    setSpinner(true);
    AxiosBase.get(`/admin/report/revenue${query}`).then((response) => {
      setRevenue(response.data.data);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getData(filter.from_date, filter.to_date);
  }, [selectedWarehouse]);

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    setFilter({
      ...filter,
      from_date,
      to_date,
    });
    getData(from_date, to_date);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData(filterDate, filterDate);
      return true;
    }
  };

  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "dateRange") {
      if (value == "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      getData(from_date, to_date);
      return true;
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const StatusCard = (props) => {
    const { amount, label } = props;
    return (
      <div className="dashboard-status-card" style={{ color: "#01b49e" }}>
        <div className="dashboard-status-card-top">
          <h2>{label}</h2>
        </div>
        <div className="dashboard-status-card-bottom">
          <div>
            <p>INR {Intl.NumberFormat("en-IN").format(amount || 0)}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Account Management</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Select
                value={selectedWarehouse}
                style={{ width: "30%" }}
                onChange={(e) => {
                  setSelectedWarehouse(e.target.value);
                }}
              >
                <MenuItem key="all" value="all">
                  All Warehouse
                </MenuItem>
                {warehouse.map((w) => {
                  return (
                    <MenuItem key={w._id} value={w._id}>
                      {w.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Filter
                handleFilter={handleFilter}
                handleClear={handleClear}
                filter={filter}
                handledates={handledates}
              />
            </div>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <br />
              <br />
              <Grid container spacing={0}>
                <Grid>
                  {StatusCard({
                    label: "Total Amount",
                    amount: revenue?.amount || 0,
                  })}
                </Grid>
                <Grid>
                  {StatusCard({
                    label: "Tax Charges",
                    amount: revenue?.tax_amount || 0,
                  })}
                </Grid>
                <Grid>
                  {StatusCard({
                    label: "Delivery Charges",
                    amount:
                      Number(revenue?.pick_delivery_charges || 0) +
                      Number(revenue?.drop_delivery_charges || 0),
                  })}
                </Grid>
                <Grid>
                  {StatusCard({
                    label: "Razorpay Charges",
                    amount: revenue?.razorpay_charge || 0,
                  })}
                </Grid>
                <Grid>
                  {StatusCard({
                    label: "In-Hand Amount",
                    amount: revenue?.net_payable || 0,
                  })}
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountList;
