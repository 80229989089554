import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import OrderDetails from "../../orderDetail";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import moment from "moment";
import Filter from "../../../utils/dateFilter/Filter";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import SecondSection from "../second-section";
import CustomerOrderChart from "../customer-order-chart";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const allStatus = [
  {
    value: "INITIATE",
    label: "Initiate",
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
  },
  {
    value: "RECEIVED_BY_RIDER_FROM_CUSTOMER",
    label: "Received By Rider From Customer",
  },
  {
    value: "WAITING_RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Waiting Received By Rider From Customer",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Received By Transporter From Rider",
  },
  {
    value: "WAITING_RECEIVED_BY_WAREHOUSE",
    label: "Waiting Received By Warehouse",
  },
  {
    value: "RECEIVED_BY_WAREHOUSE",
    label: "Received By Warehouse",
  },
  {
    value: "READY_FOR_SERVICE",
    label: "Ready For Service",
  },
  {
    value: "READY_FOR_RETURN",
    label: "Ready For Return",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_WAREHOUSE",
    label: "Received By Transporter From Warehouse",
  },
  {
    value: "WAITING_RECEIVED_BY_RIDER_FROM_TRANSPORTER",
    label: "Waiting Received By Rider From Transporter",
  },
  {
    value: "RECEIVE_BY_RIDER_FROM_TRANSPORTER",
    label: "Received By Rider From Transporter",
  },
  {
    value: "COMPLETED",
    label: "Completed",
  },
];

const typeArr = [
  {
    value: "ALL",
    label: "All Customer",
  },
  {
    value: "SELECTED",
    label: "Selected Mobile Number",
  },
  {
    value: "NO_ORDER",
    label: "Not Placed Order Customer",
  },
  {
    value: "ORDER_LIMIT",
    label: "Max N  Placed By Customer",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "520px",
    height: "4.3rem",
  },
});

// main function

const CrmList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [BulkSpinner, setBulkSpinner] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [selectedSortBy, setSelectedSortBy] = useState("_id");
  const [openBulk, setOpenBulk] = useState({
    show: false,
    title: "",
    type: "",
    message: "",
    to: "ALL",
    limit: "",
    selected: "",
  });
  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "INR",
    country: "IND",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedStatus, setSelectedStatus] = useState("all");

  const [openModal, setOpen] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState({});
  const [orderCount, setOrderCount] = React.useState("");
  const [orderCount1, setOrderCount1] = React.useState("");
  const [lastOrderDays, setLastOrderDays] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = (
    dateRange,
    from_date,
    to_date,
    currency,
    country,
    from = ""
  ) => {
    setSpinner(true);
    let ft = [];
    if (selectedStatus && selectedStatus != "all") {
      ft.push({
        key: "status",
        value: selectedStatus,
        type: "is",
      });
    }
    AxiosBase.get(
      `/admin/customer/crm?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=${selectedSortBy}&sortOrder=desc&dateRange=custom&dateFrom=${from_date}&dateTo=${to_date}&dateField=createdAt&search=` +
        search +
        `&filter=` +
        JSON.stringify(ft) + `&orderCount=${orderCount}&orderCount1=${orderCount1}&lastOrderDays=${lastOrderDays}`
    )
      .then((response) => {
        setData(response.data.data);
        setChartData(response.data.chartData);
        setCount(response.data.count);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };

  useEffect(() => {
    getData(
      filter.dateRange,
      filter.from_date,
      filter.to_date,
      filter.currency,
      filter.country,
      "start"
    );
  }, [page, rowsPerPage, search, selectedSortBy, selectedStatus, orderCount, orderCount1, lastOrderDays]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    right: false,
    data: {},
  });
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    setFilter({
      ...filter,
      from_date,
      to_date,
    });
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
  };
  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name == "dateRange") {
      if (value == "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      getData(value, from_date, to_date, filter.currency, filter.country);
      return true;
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const openView = (row) => {
    setSelectedCustomer(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openBulkModal = (type) => {
    setOpenBulk({
      show: true,
      title: "",
      type: type,
      message: "",
      to: "ALL",
      limit: "",
      selected: "",
    });
  };

  const closeBulkModal = (type) => {
    setOpenBulk({
      show: false,
      title: "",
      type: "",
      message: "",
      to: "ALL",
      limit: "",
      selected: "",
    });
  };

  const sendBulk = async () => {
    if (!openBulk?.message || !openBulk?.title) {
      return;
    }
    setBulkSpinner(true);
    AxiosBase.post(`/admin/customer/crm/bulk-sender`, openBulk)
      .then((response) => {
        closeBulkModal();
        toast(`Sucessfully Notified to ${response?.data?.count} Customers`, {
          type: "success",
          autoClose: 2000,
        });
        setBulkSpinner(false);
      })
      .catch((err) => {
        setBulkSpinner(false);
        closeBulkModal();
      });
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = e.target.value;
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else if (type == "String") {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    openBulk[key] = val;
    setOpenBulk({
      ...openBulk,
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">CRM Panel</h1>
        </Box>

        <div className="merchant_list_main_div">
          <Button
            className="crm-btn"
            variant="contained"
            startIcon={<NotificationsActiveIcon />}
            onClick={() => openBulkModal("Notification")}
            disabled={BulkSpinner}
          >
            Send Bulk Notification
          </Button>
          {/* <Button
            className="crm-btn"
            variant="contained"
            startIcon={<SmsIcon />}
            onClick={() => openBulkModal("Sms")}
            disabled={BulkSpinner}
          >
            Send Bulk SMS
          </Button> */}
          <Button
            className="crm-btn"
            variant="contained"
            startIcon={<MailIcon />}
            onClick={() => openBulkModal("Email")}
            disabled={BulkSpinner}
          >
            Send Bulk Email
          </Button>
          <hr />
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
              >
                <FormControl sx={{ m: 1, width: "100%" }} size="small">
                  <InputLabel id="new-menu"></InputLabel>
                  <CssTextField
                    className="merchant_list_search_bar"
                    id="new-menu"
                    placeholder="search"
                    variant="outlined"
                    fullWidth
                    onKeyUp={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <img src="/images/icon/search.svg" alt="" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Select
                value={selectedStatus}
                style={{ width: "100%", marginRight: "5px" }}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
              >
                <MenuItem key="all" value="all">
                  All Status
                </MenuItem>
                {allStatus.map((w) => {
                  return (
                    <MenuItem key={w.value} value={w.value}>
                      {w.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormControl sx={{ m: 1, width: "100%" }} size="small">
                <InputLabel id="demo-select-small">Sort By</InputLabel>
                <Select
                  label="Sort By"
                  value={selectedSortBy}
                  style={{ width: "100%", marginRight: "5px" }}
                  onChange={(e) => {
                    setSelectedSortBy(e.target.value);
                  }}
                >
                  <MenuItem key="_id" value="_id">
                    Created Date
                  </MenuItem>
                  <MenuItem key="wallet_amount" value="wallet_amount">
                    Wallet Amount
                  </MenuItem>
                  <MenuItem key="orderCount" value="orderCount">
                    Order Number
                  </MenuItem>
                </Select>
              </FormControl>
              <Filter
                handleFilter={handleFilter}
                handleClear={handleClear}
                filter={filter}
                handledates={handledates}
              />
            </div>
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
                style={{ width: "25%" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }} size="small">
                  <InputLabel id="new-menu"></InputLabel>
                  <CssTextField
                    type="number"
                    className=""
                    id="new-menu"
                    placeholder="less then order count"
                    variant="outlined"
                    fullWidth
                    value={orderCount}
                    onChange={(e) => {
                      setOrderCount(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
                style={{ width: "25%" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }} size="small">
                  <InputLabel id="new-menu"></InputLabel>
                  <CssTextField
                    type="number"
                    className=""
                    id="new-menu"
                    placeholder="greater then order count"
                    variant="outlined"
                    fullWidth
                    value={orderCount1}
                    onChange={(e) => {
                      setOrderCount1(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
                style={{ width: "25%" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }} size="small">
                  <InputLabel id="new-menu"></InputLabel>
                  <CssTextField
                    type="number"
                    className=""
                    id="new-menu"
                    placeholder="greater then last order days"
                    variant="outlined"
                    fullWidth
                    value={lastOrderDays}
                    onChange={(e) => {
                      setLastOrderDays(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
                style={{ width: "24%" }}
              ></Grid>
            </div>
            <h3 className="merchant_list_inner_title">Customer List</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Customer ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email ID</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Wallet Amount</TableCell>
                      <TableCell>Orders</TableCell>
                      <TableCell>Last Order Days</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow key={row?._id} style={{ background: "none" }}>
                        <TableCell>
                          {props?.menu_permissions?.EDIT ? (
                            <Link
                              to={`/customer/form/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "#1058ff",
                              }}
                            >
                              {row._id}
                            </Link>
                          ) : (
                            row._id
                          )}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.mobile_no}</TableCell>
                        <TableCell>
                          {(row.wallet_amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>{row?.orderCount || 0}</TableCell>
                        <TableCell>{row?.daysSinceLastOrder || "No"}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD-MMM-YYYY HH:MM A")}
                        </TableCell>
                        <TableCell>
                          {props?.menu_permissions?.EDIT && (
                            <IconButton
                              className="merchant_tbl_edit_icon"
                              aria-label="more"
                              id="long-button"
                              aria-controls="long-menu"
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={() => {
                                openView(row);
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>

        {spinner ? (
          <></>
        ) : (
          <SecondSection
            data={
              chartData && chartData.length ? chartData[0].customer_detail : []
            }
            filter={filter}
          />
        )}

        <BootstrapDialog
          className="customer-order-chart"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
        >
          <DialogContent>
            <CustomerOrderChart customer_id={selectedCustomer?._id} />
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          className="bulk-modal"
          onClose={closeBulkModal}
          aria-labelledby="customized-dialog-title"
          open={openBulk?.show}
        >
          <DialogContent>
            <div className="bulk-inner">
              <h3>Send {openBulk?.type} In Bulk</h3>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
              >
                <Select
                  value={openBulk?.to}
                  style={{ width: "100%", marginRight: "5px" }}
                  onChange={(e) => {
                    onChangeInput(e, "to", "String");
                  }}
                >
                  {typeArr.map((w) => {
                    return (
                      <MenuItem key={w.value} value={w.value}>
                        {w.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {openBulk?.to === "SELECTED" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="merchant_grid_sec"
                >
                  <CssTextField
                    className=""
                    id="new-menu"
                    type="text"
                    placeholder="select comma separate mob no"
                    variant="outlined"
                    value={openBulk?.selected}
                    onChange={(e) => onChangeInput(e, "selected", "String")}
                  />
                </Grid>
              )}

              {openBulk?.to === "ORDER_LIMIT" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="merchant_grid_sec"
                >
                  <CssTextField
                    className=""
                    id="new-menu"
                    type="number"
                    placeholder="enter order no"
                    variant="outlined"
                    value={openBulk?.limit}
                    onChange={(e) => onChangeInput(e, "limit", "String")}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className=""
                  id="new-menu"
                  type="text"
                  placeholder="subject"
                  variant="outlined"
                  value={openBulk?.title}
                  onChange={(e) => onChangeInput(e, "title", "String")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <CssTextArea
                  className="bulk-input"
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderColor: "#c4c4c4",
                    p: "10px",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                  placeholder="message"
                  value={openBulk?.message}
                  onChange={(e) => onChangeInput(e, "message", "String")}
                  minRows={3}
                />
              </Grid>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={sendBulk}
                  disabled={BulkSpinner}
                >
                  Submit
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={closeBulkModal}
                  fullWidth
                  disabled={BulkSpinner}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CrmList;
